import * as React from 'react';
// @ts-ignore
import { HeroSearch } from 'career-page-components';
import './JobSearchForm.css';

interface JobSearchFormProps {
	data: any;
}
const JobSearchForm: React.FC<JobSearchFormProps> = (
	props: JobSearchFormProps
) => {
	return (
		<section
			className={
				'section-job-search-form font-encode flex-col flex max-md:mx-auto bg-[#F0F0F0] border-t-8 border-[#F29E00] max-w-md p-5 px-10'
			}
		>
			<p className={'text-sl-blue text-3xl pb-8'}>Search Current Openings</p>
			<HeroSearch
				data={props.data}
				options={{
					submitButtonText: 'Search Jobs',

				}}
				apiKey={'AIzaSyCPLqR_SVgctRijc8zUg_bCvsT3eh9ngoQ'}
			/>
		</section>
	);
};

export default JobSearchForm;
