import * as React from 'react';
import type { HeadFC, PageProps } from 'gatsby';
import Layout from '../../layouts/Layout';

// @ts-ignore
import hero from '../../images/hero-3.png';
// @ts-ignore
import logoIcon from '../../images/logo-icon.svg';
// @ts-ignore
import image2 from '../../images/image2.png';
// @ts-ignore
import back from '../../images/back.svg';
// @ts-ignore
import image1 from '../../images/stellantis-samsung-scaled-1.png';
// @ts-ignore
import imageBG from '../../images/image-bg-about.png';
// @ts-ignore
import primxLogo from '../../images/primx.svg';
// @ts-ignore
import batteries from '../../images/batteries.png';

import { graphql, useStaticQuery } from 'gatsby';
import JobSearchForm from '../../components/JobSearchForm/JobSearchForm';

const AboutPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout version={1} text={''} title={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'} desc={'Careers - StarPlus Energy - A Samsung SDI + Stellantis JV'}>
			<div
				style={{ backgroundImage: `url('${hero}')` }}
				className={
					'flex h-[520px] w-full flex-col justify-end bg-cover bg-top max-md:items-center md:h-[800px]'
				}
			>
				<div className={'container mx-auto px-5'}>
					<div className={'w-full md:w-1/2'}>
						<JobSearchForm data={data} />
					</div>
				</div>
			</div>

			<div className={'container mx-auto px-5 pt-6 pb-2'}>
				<a href={'/'} className={'flex flex-row text-sl-blue'}>
					<img className={'pr-2'} src={back} alt={'back'} />
					Back
				</a>
			</div>

			<div className={'container mx-auto pb-6'}>
				<div className={'flex flex-row max-lg:flex-col-reverse'}>
					<div className={'flex flex-col lg:w-1/2'}>
						<img src={image1} />
					</div>
					<div className={'flex flex-col p-10 lg:w-1/2'}>
						<h2
							className={
								'font-encode text-5xl font-300 text-sl-blue'
							}
						>
							Working for the future of the industry, and the
							planet
						</h2>
						<p className={'mt-8 font-encode text-xl'}>
							StarPlus Energy LLC is a joint venture created by
							Stellantis and Samsung SDI. We are uniquely
							positioned to offer innovative and sustainable
							solutions that meet the evolving needs of customers
							as they embrace electrification.
						</p>
					</div>
				</div>
			</div>
			<div className={'bg-[#F4F4F4] px-5'}>
				<div className={'container mx-auto py-12'}>
					<div
						className={
							'mx-auto flex max-w-4xl flex-col items-center text-center'
						}
					>
						<h2
							className={
								'pb-4 font-encode text-3xl font-300 text-sl-blue'
							}
						>
							Building powerful potential
						</h2>
						<p className={'text-body mt-4 font-encode text-xl'}>
							In partnership with the Kokomo Plan Commission, we
							began our two-year construction project in September
							2022 on the StarPlus Energy electric vehicle battery
							plant on Kokomo’s far northeast side. Construction
							on the 2.6 million square-foot plant is estimated to
							finish in 2025. At its peak, this construction
							project will employ about 2,000 workers, and once
							complete, the plant will create 1,400 new jobs.
						</p>
						<p className={'text-body mt-4 font-encode text-xl'}>
							We remain deeply rooted in the communities in which
							we operate and where our colleagues live and work.
							As part of our commitment to Kokomo, we included
							infrastructure upgrades in our agreement, including
							infrastructure improvements to provide fire
							protection service to the project site, an
							investment in the wastewater collection system, and
							more.
						</p>
					</div>
				</div>
			</div>
			<div
				style={{ backgroundImage: `url('${imageBG}')` }}
				className={'min-h-[640px] w-full bg-cover bg-center py-20'}
			>
				<div className={'container mx-auto flex flex-row h-full'}>
					<div className={'flex lg:w-1/2 flex-col items-center justify-center h-full'}>
						<div style={{ maxWidth: '580px', backgroundColor: 'rgba(255, 255, 255, 0.9)' }}
							className={'p-6 border-t-8 border-sl-yellow'}>
							<h2 className={'py-6 font-encode text-3xl font-300 text-sl-blue'}>Working together</h2>
							<p className={'pb-4 font-encode text-xl font-300 font-body leading-[27px]'}>
								Our strength lies in the backgrounds, passions,
								and experiences of our employees. No matter what
								position we are hiring for, we never lose sight
								of our values.
							</p>

							<p className={'pb-4 font-encode text-xl font-300 font-body leading-[27px]'}>
								<b className={'font-bold'}>Innovation:</b> We shape the lives of
								billions of people from all over the world
								through sustainable experiences that meet
								customers’ new expectations.
							</p>

							<p className={'pb-4 font-encode text-xl font-300 font-body leading-[27px]'}>
								<b className={'font-bold'}>Inclusion:</b> We embrace and celebrate the
								diversity of each and every one of our
								employees.
							</p>

							<p className={'pb-4 font-encode text-xl font-300 font-body leading-[27px]'}>
								<b className={'font-bold'}>Leadership:</b> We will continually encourage
								our people to pursue greatness but never at the
								cost of sustainability.
							</p>
						</div>
					</div>
					<div className={'flex lg:w-1/2 flex-col'}></div>
				</div>
			</div>
			<div className={'container px-5 mx-auto flex flex-col items-center py-12'}>
				<img src={primxLogo} alt={'Primx Logo'} />
				<p className={'pt-10 leading-[32px] font-encode text-xl text-sl-body text-center'}>PRiMX is built on three key phrases, absolute quality, outstanding performance and proven advantage.
					To ensure absolute quality, Samsung SDI employs a defect detection algorithm with a deep
					learning-based artificial intelligence test, examining some 500 processes throughout the production.
					For outstanding performance, Samsung SDI is applying its latest materials technology including a
					high-nickel cathode and silicon anode, and Proven Advantage means user convenience achieved with the
					company’s independently developed technology. In particular, the super-fast charging technology.</p>
				<img src={batteries} className={'py-20'} alt={'Batteries'} />
				<a
					className="dn d-f-xl max-lg:max-w-[200px] bg-sl-blue px-10 py-2 mt-6 text-center hover:text-white text-white"
					href={'/'}
					target={'_blank'}
				>
					<button>Return Home</button>
				</a>
			</div>
		</Layout>
	);
};

export default AboutPage;
